import { useState, State, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useStopwatch } from 'react-timer-hook';
import { BrowserRouter as Router, useSearchParams } from 'react-router-dom';

import logo from './logo.svg';
import nlWords from './data/wordlist_nl_NL.txt';
import './App.css';
// import { beeWords } from './data/bee_out.json';
// var beeWords = require("./data/nytbeewords.json");
var beeWords = require("./data/bee_out_short_nl_NL.json");

function ShuffleString(string) {
  var temp = string.split("");
  ShuffleArray(temp);
  return temp.join("");
}

function ShuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var n = Math.floor(Math.random() * (i + 1));
    [array[i], array[n]] = [array[n], array[i]];
  }

}

function App() {
  return (
    <Router>
      <Game />
    </Router>
  )
}

function Game() {
  const [searchParams] = useSearchParams();

  const [attempt, setAttempt] = useState("");
  const [letters, setLetters] = useState("ppppppp");
  const [wordlist, setWordlist] = useState([]);
  const [pangramList, setPangramList] = useState([]);

  const [foundWords, setFoundWords] = useState([]);
  const [score, setScore] = useState(0);
  const [rank, setRank] = useState(0);
  const [maxScore, setMaxScore] = useState(0);

  const [time, setTime] = useState(0);

  const [statsOpen, setStatsOpen] = useState(false);
  const [answersOpen, setAnswersOpen] = useState(false);
  const [hintsOpen, setHintsOpen] = useState(false);

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  // console.log(beeWords);
  useEffect(() => {
    var paramLetters = searchParams.get("letters");
    if (paramLetters != null) {
      var sortedParamLetters = paramLetters.split("").sort().join("");
    }
    var paramTime = searchParams.get("time");
    var paramScore = searchParams.get("score");
    console.log(paramLetters, paramTime, paramScore);

    // Pick a 7-letter set
    var letterSets = Object.keys(beeWords);
    var letterSet, beeSet;
    if (paramLetters != null && letterSets.indexOf(sortedParamLetters) > -1) {
      letterSet = sortedParamLetters;
      beeSet = paramLetters;
    } else {
      if (paramLetters != null) {
        setSnackbarOpen(true);
        setSnackbarMessage("De opgegeven letters zijn niet geschikt...");
      }
      letterSet = letterSets[Math.floor(letterSets.length * Math.random())];
      // Select a random center letter
      beeSet = ShuffleString(letterSet);
    }
    var pangrams = beeWords[letterSet]["pangrams"];

    // Filter the word list for the center letter
    var wordList = beeWords[letterSet]["words"].filter((word) => word.includes(beeSet[0]));
    var maxScore = wordList.map((w) => w.length === 4 ? 1 : w.length).reduce((s, x) => s + x, 0);
    console.log(maxScore);
    maxScore += pangrams.map((p) => p.length).reduce((s, x) => s + x, 0) + pangrams.length * 7;
    console.log(maxScore);

    setLetters(beeSet);
    setWordlist(wordList);
    setPangramList(pangrams);
    setMaxScore(maxScore);

    console.log(letterSet, beeSet, pangrams, wordList, sortedParamLetters);
  }, []);

  // const [snackbarState, setSnackbarState] = useState<State>({
  //   open: false,
  //   message: "Too short"
  // });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  function ToggleMenu(menuValue, menuSetter) {
    // (un)pause the stopwatch
    if (menuValue) {
      pause();
    } else {
      start();
    }

    menuSetter(menuValue);
  }

  function AddToAttempt(letter) {
    setAttempt(attempt + letter);

    if (attempt.length > 19) {
      setSnackbarOpen(true);
      setSnackbarMessage("Te lang!");
      setAttempt("");
    }
  }

  // var ranks = [
  //   "Beginner",
  //   "Good Start",
  //   "Moving Up",
  //   "Good",
  //   "Solid",
  //   "Nice",
  //   "Great",
  //   "Amazing",
  //   "Genius",
  //   "Koninginnebij"
  // ]

  var ranks = [
    "Beginneling",
    "Goede Start",
    "De Weg Omhoog",
    "Goed",
    "Prima",
    "Geweldig",
    "Groots",
    "Onvoorstelbaar",
    "Geniaal",
    "Bijenkoningin"
  ]

  var rankPercents = [
    0.00,
    0.02,
    0.05,
    0.08,
    0.15,
    0.25,
    0.40,
    0.50,
    0.70,
    1.00
  ]

  function Enter() {
    var message = "Poging mislukt";
    if (attempt.length === 0) {
      // Don't alert
      return;
    } else if (attempt.length < 4) {
      message = "Te kort";
    } else if (attempt.split('').map((letter) => letters.includes(letter)).includes(false)) {
      message = "Verkeerde letters";
    } else if (!attempt.includes(letters[0])) {
      message = "Mist de centrale letter";
    } else if (!wordlist.includes(attempt) && !pangramList.includes(attempt)) {
      message = "Niet in de woordenlijst";
    } else if (foundWords.includes(attempt)) {
      message = "Al gevonden"
    } else {
      let wordScore = 0;
      if (attempt.length === 4) {
        message = "Goed!";
        wordScore = 1;
      } else if (pangramList.includes(attempt)) {
        message = "Pangram!";
        wordScore = attempt.length + 7;
      } else if (attempt.length > 6) {
        message = "Geweldig!";
        wordScore = attempt.length;
      } else {
        message = "Prima!";
        wordScore = attempt.length;
      }

      setFoundWords([attempt, ...foundWords].sort());
      setScore(score + wordScore);

      var percent = (score + wordScore) / maxScore;
      if (percent >= 1.0) {
        setRank(9);
      } else {
        setRank(rankPercents.findIndex((pc) => pc > percent) - 1);
      }
    }

    setSnackbarOpen(true);
    setSnackbarMessage(message);
    setAttempt("");
  }

  function Delete() {
    setAttempt(attempt.slice(0, -1));
  }

  function Shuffle() {
    setLetters(letters[0] + letters.split('').slice(1).sort(function () { return 0.5 - Math.random() }).join(''));
  }

  function CloseSnackbar() {
    setSnackbarOpen(false)
  }

  function KeyDown(event) {
    if (event.key === "Backspace") {
      Delete();
    } else if (event.key === "Enter") {
      Enter();
    } else if (event.key === " ") {
      Shuffle();
    } else if (event.keyCode <= 64 || event.keyCode >= 91) {
      // Bad key, do nothing
      console.log(event.keyCode, event.key);
      return;
    } else {
      AddToAttempt(event.key);
    }
  }

  return (
    <div className="App">
      <div className="game" tabIndex={0} onKeyDown={KeyDown}>
        <div className="title">
          <h3>Spelbij</h3>
          <span>
            een remake van de&nbsp;<a href="https://www.nytimes.com/puzzles/spelling-bee" target="_blank" rel="noopener noreferrer">NYT Spelling Bee</a>, met een paar twisten
          </span>
        </div>
        <TimerMenu minutes={minutes} seconds={seconds} onClick={pause} />
        <div className="menus-bar">
          <div className="menu-item" onClick={() => ToggleMenu(true, setStatsOpen)}>Stats</div>
          <div className="menu-item">Antwoorden</div>
          <div className="menu-item">Hints</div>
          <div className="menu-item">Meer</div>
        </div>
        <div className="letter-box">
          <Snackbar
            autoHideDuration={1000}
            open={snackbarOpen}
            message={snackbarMessage}
            onClose={CloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          />
          <div className="letter-bar">{attempt.split("").map((l, i) => <AttemptLetter letter={l} i={i} inLetters={letters.includes(l)} coreLetter={letters[0] === l} />)}</div>
          <div className="hex-box">
            <div className="hex-row odd">
              {LetterHex(letters[1], AddToAttempt)}
              {LetterHex(letters[2], AddToAttempt)}
            </div><br />
            <div className="hex-row even">
              {LetterHex(letters[3], AddToAttempt)}
              {LetterHex(letters[0], AddToAttempt, true)}
              {LetterHex(letters[4], AddToAttempt)}
            </div><br />
            <div className="hex-row odd">
              {LetterHex(letters[5], AddToAttempt)}
              {LetterHex(letters[6], AddToAttempt)}
            </div>
          </div>
          <div className="game-buttons">
            <button onClick={Delete}>Verwijder</button>
            <button onClick={Shuffle}>♻️</button>
            <button onClick={Enter}>Enter</button>
          </div>
        </div>
        <div className="score-root">
          <div className="score-bar">
            <h4 className="score-name">{ranks[rank]}</h4>
            <div className="score-graphics">
              <div className="score-line">
                <div className="score-dots">
                  <div className={`score-dot${rank > -1 ? ' completed' : ''}`} />
                  <div className={`score-dot${rank > 0 ? ' completed' : ''}`} />
                  <div className={`score-dot${rank > 1 ? ' completed' : ''}`} />
                  <div className={`score-dot${rank > 2 ? ' completed' : ''}`} />
                  <div className={`score-dot${rank > 3 ? ' completed' : ''}`} />
                  <div className={`score-dot${rank > 4 ? ' completed' : ''}`} />
                  <div className={`score-dot${rank > 5 ? ' completed' : ''}`} />
                  <div className={`score-dot${rank > 6 ? ' completed' : ''}`} />
                  <div className={`score-dot square${rank > 7 ? ' completed' : ''}`} />
                </div>
              </div>
              <div className="score-marker" style={{ left: (0 + 12.5 * Math.min(rank, 8)) + "%" }}><div className={`score-score${rank > 8 ? ' queen' : ''}`}>{score}</div></div>
            </div>
          </div>
          <FoundWordList foundWords={foundWords} />
        </div>
      </div>
      <div className={`overlay-items ${(statsOpen || answersOpen || hintsOpen) ? 'open' : 'closed'}`} onClick={() => { setAnswersOpen(false); setHintsOpen(false); setStatsOpen(false) }}>
        <div className={`modal stats ${statsOpen ? 'open' : 'closed'}`}>
          <span className="title">Stats</span>
          <div className="stats-block">
            <div>Tijd:</div><div>{('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}</div>
            <div>Woorden gevonden:</div><div>{`${foundWords.length} (${(foundWords.length / wordlist.length).toFixed(1)}%)`}</div>
          </div>
        </div>
      </div>
      <div className="footer">
        <span><b>Spelbij</b></span> is a remake of the <a href="https://www.nytimes.com/puzzles/spelling-bee" target="_blank" rel="noopener noreferrer">NYT Spelling Bee</a>, a game edited by Sam Ezersky. None of this is endorsed or approved by the <span>New York Times</span>. All of this was made to educate myself on HTML, CSS, Javascript and React.
        <address>For any issues, questions or tips, message me on <a href="https://twitter.com/messages/compose?recipient_id=2916132622&text=About%20Spelbij%3A" target="_blank" rel="noopener noreferrer">Twitter</a>.</address>
      </div>
    </div>
  );
}

function TimerMenu(props) {
  const { minutes, seconds, onClick } = props;

  return (
    <div className="menu-clock" onClick={() => onClick()}>
      {('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}⏸︎
    </div>
  );

}

function LetterHex(letter, onPress, core) {
  // console.log(`Core? ${core}`);
  const [isPressed, setIsPressed] = useState(false);

  function pressHex(event) {
    if (event.type === "mousedown") {
      setIsPressed(true);
      onPress(letter);
    }
    else if (event.type === "mouseleave" || event.type === "mouseup")
      setIsPressed(false)
  }

  return (
    <div className={`letter-hex ${core ? 'core' : ''} ${isPressed ? 'pressed' : ''}`} onMouseDown={pressHex} onMouseLeave={pressHex} onMouseUp={pressHex}>
      {letter}
    </div>
  )
}

function AttemptLetter(props) {
  const { letter, i, inLetters, coreLetter } = props;
  return (
    <span className={`attempt-letter${!inLetters ? " not-in" : ""}${coreLetter ? " core" : ""}`} key={`letter-${i}`}>{letter}</span>
  )
}

function FoundWordList(props) {
  const { foundWords } = props;

  return (
    <div className="wordlist-root">
      <div className="wordlist-summary">Je hebt {foundWords.length} woord{foundWords.length === 1 ? "" : "en"} gevonden</div>
      <ul className="wordlist-list">
        {foundWords.map((word) => <li className={`wordlist-word${(new Set(word).size === 7 ? " pangram" : "")}`} key={word}>{word}</li>)}
      </ul>
    </div>
  );
}

export default App;
